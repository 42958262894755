window.Axios = require('axios');
import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/sass/main.scss";
import inViewport from "./directive/inViewport";
import btn from "./directive/btn";
import VueScrollTo from 'vue-scrollto';

//import components
import Navbar from "@/js/components/Navbar";
import Foooter from "@/js/components/Foooter";
import Carousel from "@/js/components/Carousel";
import CommentsSlider from "@/js/components/CommentsSlider";
import BadgeScroller from "@/js/components/BadgeScroller";
import Appointment from "@/js/components/appointment";
// import test from "@/js/components/test";
// import test from "@/js/components/test";
const app = createApp(App)
        .use(store)
        // .use(router)
        //components
        .component('navbar', Navbar)
        // .component('test', test)
        .component('foooter', Foooter)
        .component('Carousel', Carousel)
        .component('CommentsSlider', CommentsSlider)
        .component('BadgeScroller', BadgeScroller)
        .component('Appointment', Appointment)
        //directives
        .use(VueScrollTo, {
            container: "body",
            lazy: false,
            duration: 50,
            easing: ".5,0,.35,1",
            offset: 0,
            force: true,
            cancelable: true,
            onStart: false,
            onDone: false,
            onCancel: false,
            x: false,
            y: true
        })
        .directive('in-viewport', inViewport)
        .directive('btn', btn);
app.mount('#app');