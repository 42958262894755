<template>
	<div class="comments-slider">
		<div class="arrow-cont left" @click="slide('left')">
			<img :src="$store.getters.getPaths.media +'img/icons/arrow-left.svg'">
		</div>
		<div class="comments">
			<div class="comments-wrapper" ref="inner" :style="innerStyles">
				<div v-for="(item,index) in slides" class="comment" :class="[{'active':index===2}]" :key="item.id">
					<q :class="qClasses(index)">{{ item.comment }}</q>
					<div class="user-info">
						<div class="avatar-cont">
							<img :src="$store.getters.getPaths.media + item.avatar">
						</div>
						<div class="user-company">
							<b>{{ item.name }}</b>
							<span>{{ item.company }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="arrow-cont right" @click="slide('right')">
			<img :src=" $store.getters.getPaths.media + 'img/icons/arrow-right.svg'">
		</div>
	</div>
</template>
<script>
export default {
	name: 'CommentsSlider',
	computed: {},
	props: {},
	data() {
		return {
			innerStyles: {},
			translate: '',
			transitioning: false,
			slides: [
				{
					id: 0,
					active: true,
					avatar: "media/comments/Rik.jpg",
					name: "Rik Meijer",
					company: "Hestiva",
					comment: "I have a very good experience with Dotswan. Thanks to the dedicated team that is always there for you, you are very well supported in all processes. They have helped me a lot in developing all my software. Nothing is too crazy for them! Many thanks for your help. I will recommend it to everyone!"
				},
				{
					id: 1,
					active: false,
					avatar: "media/comments/Jullaya.jpeg",
					name: "Jullaya Vorasuntharosoth",
					company: "SOMI",
					comment: "I had a very pleasant collaboration with Dotswan. They delivered high quality work in a given time frame and responded to every feedback. Definitely recommended."
				},
				{
					id: 2,
					active: false,
					avatar: "media/comments/avatar-3.png",
					name: "Courtney Henry",
					company: "ZenithSphere",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
				{
					id: 3,
					active: false,
					avatar: "media/comments/avatar-2.png",
					name: "Cameron Williamson",
					company: "NexusWorks",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
				{
					id: 4,
					active: false,
					avatar: "media/comments/avatar.png",
					name: "Bessie Cooper",
					company: "LeadMind",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
				{
					id: 5,
					active: false,
					avatar: "media/comments/avatar-3.png",
					name: "Courtney Henry",
					company: "ZenithSphere",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
				{
					id: 6,
					active: false,
					avatar: "media/comments/avatar-2.png",
					name: "Cameron Williamson",
					company: "NexusWorks",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
				{
					id: 7,
					active: false,
					avatar: "media/comments/avatar.png",
					name: "Bessie Cooper",
					company: "LeadMind",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
				{
					id: 8,
					active: false,
					avatar: "media/comments/avatar-3.png",
					name: "Courtney Henry",
					company: "ZenithSphere",
					comment: "I was blown away by the quality of candidates that the Recruit Talent agency presented to us. They were able to find talent that we never would have discovered on our own, and we ended up hiring several of their top recommendations. I can't recommend them enough!"
				},
			]
		}
	},
	methods: {
		qClasses(index) {
			if (index === 2) {
				return "animate__animated animate__fadeInUp"
			} else {
				return "animate__animated animate__fadeOutDown"
			}
		},
		swipeSupport() {
			let container = this.$refs.inner,
					initialX = null,
					initialY = null,
					_this = this;
			container.addEventListener("touchstart", startTouch, false);
			container.addEventListener("touchmove", moveTouch, false);

			function startTouch(e) {
				initialX = e.touches[0].clientX;
				initialY = e.touches[0].clientY;
			}

			function moveTouch(e) {
				if (initialX === null) {
					return;
				}

				if (initialY === null) {
					return;
				}

				var currentX = e.touches[0].clientX;
				var currentY = e.touches[0].clientY;

				var diffX = initialX - currentX;
				var diffY = initialY - currentY;

				if (Math.abs(diffX) > Math.abs(diffY)) {
					// sliding horizontally
					if (diffX > 0) {
						// swiped left
						_this.slide('left');
						// console.log("swiped left");
					} else {
						// swiped right
						_this.slide('right');
						// console.log("swiped right");
					}
				} else {
					// sliding vertically
					if (diffY > 0) {
						// swiped up
						// console.log("swiped up");
					} else {
						// swiped down
						// console.log("swiped down");
					}
				}

				initialX = null;
				initialY = null;

				e.preventDefault();
			};
		},
		setUp() {
			this.calcTranslate();
			this.resetTranslate();
			this.swipeSupport();
			this.watchResize();
		},
		resized() {
			this.calcTranslate();
			this.resetTranslate();
		},
		watchResize() {
			window.addEventListener("resize", this.resized);
		},
		calcTranslate() {
			let innerWidth = this.$refs.inner.scrollWidth,
					totalslides = this.slides.length;
			if (this.$store.getters.getMobile || this.$store.getters.getDevice.width <= 720) {
				console.log(this.$store.getters.getDevice.width)
				this.translate = 0;
			} else {
				this.translate = `${innerWidth / totalslides}px`;
			}
		},
		slide(direction) {
			if (direction === 'right') {
				if (this.transitioning)
					return

				this.transitioning = true
				this.moveLeft()
				this.afterTransition(() => {
					const card = this.slides.shift()
					this.slides.push(card)
					this.resetTranslate()
					this.transitioning = false
				})
			} else {
				if (this.transitioning)
					return
				this.transitioning = true
				this.moveRight()
				this.afterTransition(() => {
					const card = this.slides.pop()
					this.slides.unshift(card)
					this.resetTranslate()
					this.transitioning = false
				})
			}
		},
		moveLeft() {
			this.innerStyles = {
				transform: `translateX(-${this.translate})
                    translateX(-${this.translate})`
			}
		},
		moveRight() {
			this.innerStyles = {
				transform: `translateX(${this.translate})
                    translateX(-${this.translate})`
			}
		},
		afterTransition(callback) {
			const listener = () => {
				callback()
				this.$refs.inner.removeEventListener('transitionend', listener)
			}
			this.$refs.inner.addEventListener('transitionend', listener)
		},
		resetTranslate() {
			this.innerStyles = {
				transition: 'none',
				transform: `translateX(-${this.translate})`
			}
		}
	},
	mounted() {
		this.setUp();
	}
}
</script>