<template>
	<navbar>
		<a href="/" class="navbar-logo">
			<span class="logo-pack white"><i class="dot"></i><b class="skill">dotSkill</b></span>
		</a>
		<div class="navbar-menu">
			<nav class="navbar-menu-items flex f-gap-8 f-align-center">
				<a href="" v-scroll-to="{el: '#how-we-work'}" class="uppercase navbar-menu-item color-white">
					<span class="hovergrad">How we work</span>
				</a>
				<a href="" v-scroll-to="{el: '#our-services'}" class="uppercase navbar-menu-item color-white">
					<span class="hovergrad">Our Services</span>
				</a>
				<a href="" v-scroll-to="{el: '#testimonials'}" class="uppercase navbar-menu-item color-white">
					<span class="hovergrad">Testimonials</span>
				</a>
				<a class="btn outline white uppercase navbar-menu-item" href="#"
				   v-scroll-to="{el: '#contact-form'}">Contact
				                                       us</a>
			</nav>
		</div>
	</navbar>
	<main class="main-view-cont">
		<!--		<router-view/>-->
		<section class="hero color-white back-gray_dark padding80 padding012 container">
			<div class="equal-cols g-gap-8 small-reverse-dir-col">
				<div class="col-inner">
					<div class="flex f-align-center">
						<img :src="$store.getters.getPaths.media + 'img/icons/spark.svg'" class="spark">
						<h1 class="fs-20 lh-12 fw-300 padding01%">Hiring Made Easy</h1>
					</div>
					<h2 class="capital mt-4 mb-3 lh-12 fw-800 fs-50 animatedTitle"><span>Hire a Top-Notch</span> <span>Remote Team</span>
					</h2>
					<p class="desc fs-20 lh-14 fw-100 mb-5 animate__fadeIn"
					   v-in-viewport="{class:['animate__animated']}">We
					                                                 find
					                                                 the
					                                                 best
					                                                 talent
					                                                 so
					                                                 you
					                                                 can
					                                                 focus
					                                                 on
					                                                 what
					                                                 you
					                                                 do
					                                                 best.
					                                                 Ready
					                                                 to
					                                                 take
					                                                 your
					                                                 team
					                                                 to
					                                                 the
					                                                 next
					                                                 level?</p>
					<div class="flex f-gap-6 f-align-center">
						<a href="" class="btn blue uppercase" v-btn v-scroll-to="{el: '#contact-form'}">Get Started</a>
						<a href="" class="btn white underline uppercase" v-scroll-to="{el: '#how-we-work'}">
						<span class="hovergrad">
							<span>Learn More</span>
						</span>
						</a>
					</div>
				</div>
				<div class="col-inner">
					<div class="talents-badge relative">
						<div class="talents">
							<div class="starter"></div>
							<div class="beams">
								<img :src=" $store.getters.getPaths.media +'img/beams.svg'">
							</div>
							<div class="ellipse"></div>
							<div class="girl">
								<img :src="$store.getters.getPaths.media + 'img/girl.png'">
							</div>
							<div class="boy">
								<img :src="$store.getters.getPaths.media +'img/boy.png'">
							</div>
						</div>
						<div class="badge animate__animated animate__fadeIn">
							<img :src=" $store.getters.getPaths.media +'img/unlock-badge.png'" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="summaries-scroll flex f-space-between f-align-center hide-mobile">
				<div class="summaries flex">
					<div class="summary-item animate__fadeIn delay-1" v-in-viewport="{class:['animate__animated']}">
						<div class="img-pack">
							<img :src="$store.getters.getPaths.media +'media/summaries/logo-1.png'">
							<img :src="$store.getters.getPaths.media +'media/summaries/logo-3.png'">
							<img :src="$store.getters.getPaths.media +'media/summaries/logo-2.png'">
						</div>
						<div class="info">
							<b>120+</b>
							<span>Happy Client</span>
						</div>
					</div>
					<div class="summary-item animate__fadeIn delay-2" v-in-viewport="{class:['animate__animated']}">
						<div class="img-pack">
							<img :src="$store.getters.getPaths.media +'media/summaries/avatar-1.png'">
							<img :src="$store.getters.getPaths.media +'media/summaries/avatar-2.png'">
							<img :src="$store.getters.getPaths.media +'media/summaries/avatar-3.png'">
						</div>
						<div class="info">
							<b>8+</b>
							<span>Talents</span>
						</div>
					</div>
					<div class="summary-item animate__fadeIn delay-3" v-in-viewport="{class:['animate__animated']}">
						<div class="icon">
							<img :src="$store.getters.getPaths.media +'img/icons/folder.svg'">
						</div>
						<div class="info">
							<b>350+</b>
							<span>Projects</span>
						</div>
					</div>
				</div>
				<div class="scroll">
					<img :src="$store.getters.getPaths.media +'img/icons/scroll-down.svg'">
				</div>
			</div>
		</section>
		<section id="how-we-work" class="how-we-work color-gray_dark back-white padding80 ">
			<div class="container padding012">
				<div class="equal-cols small-dir-col g-gap-8 ">
					<div class="col-inner">
						<h3 class="mb-2 fs-40 lh-12 fw-800" v-in-viewport="{class:['animatedTitle', 'delay-3']}">
							<span>How we work?</span>
						</h3>
						<p class="desc fs-20 fw-300 lh-14 animate__fadeIn delay-1"
						   v-in-viewport="{class:['animate__animated']}">At dotSkill, we're passionate about connecting
						                                                 the
						                                                 right people with
						                                                 the right
						                                                 opportunities.
						                                                 Our team of experienced recruiters specializes
						                                                 in
						                                                 finding top talent
						                                                 in a variety of
						                                                 industries.
							<br>
						                                                 Our mission is to make the hiring process as
						                                                 easy
						                                                 and
						                                                 efficient as
						                                                 possible for both
						                                                 job seekers
						                                                 and
						                                                 employers. We believe that finding the perfect
						                                                 fit
						                                                 is
						                                                 about more than
						                                                 just matching
						                                                 skills and
						                                                 experience - it's about finding a candidate who
						                                                 aligns
						                                                 with your
						                                                 company's culture,
						                                                 values, and
						                                                 goals.</p>
					</div>
					<div class="col-inner">
						<div class="lets-rock relative" v-in-viewport="{class:['show']}">
							<div class="text-container">
								<div class="text" v-for="i in 26">Let’s Rock Your Team</div>
							</div>
							<div class="graphic">
								<div class="hand">
									<picture>
										<source media="(max-width: 720px)" srcset="img/lets-rock@1x.png"/>
										<source media="(min-width: 720px)" srcset="img/lets-rock@2x.png"/>
										<img src="img/lets-rock@2x.png">
									</picture>
								</div>
								<div class="hole">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="matter color-white back-blue padding80">
			<div class="container padding012">
				<div class="matter-inner">
					<div class="col-inner">
						<div class="stairs">
							<img src="img/stairs-cropped.svg">
						</div>
					</div>
					<div class="col-inner">
						<h3 class="color-white ff-code lh-100 fs-135"><span>Make Every Move Matter</span></h3>
					</div>
				</div>
			</div>
		</section>
		<section id="our-services" class="services pt-8 color-white back-blue">
			<div class="container">
				<div class="padding012 pb-8">
					<dl class="services-list">
						<div class="service-item">
							<dt>
								<h6 class="fs-32 fw-900 lh-14 pl-3 animate__fadeInUp"
								    v-in-viewport="{class:['animate__animated']}">Talent Sourcing</h6></dt>
							<dd class="fs-20 color-white fw-100 lh-14 animate__fadeIn delay-1"
							    v-in-viewport="{class:['animate__animated']}">We use a variety of methods to identify
							                                                  and
							                                                  source
							                                                  top
							                                                  talent for your company, including online
							                                                  job
							                                                  boards, social media recruiting, and
							                                                  targeted
							                                                  outreach to
							                                                  passive candidates.
							</dd>
						</div>
						<div class="service-item">
							<dt><h6 class="fs-32 fw-900 lh-14 pl-3 animate__fadeInUp delay-2"
							        v-in-viewport="{class:['animate__animated']}">Interviewing and Selection</h6></dt>
							<dd class="fs-20 color-white fw-100 lh-14 animate__fadeIn delay-3"
							    v-in-viewport="{class:['animate__animated']}">We carefully review resumes and
							                                                  applications
							                                                  to
							                                                  ensure
							                                                  that only the most qualified candidates
							                                                  are
							                                                  considered for open positions. We also
							                                                  conduct
							                                                  initial
							                                                  screenings to assess candidates' skills,
							                                                  experience, and fit for your company
							                                                  culture.
							</dd>
						</div>
						<div class="service-item">
							<dt><h6 class="fs-32 fw-900 lh-14 pl-3 animate__fadeInUp delay-4"
							        v-in-viewport="{class:['animate__animated']}">Onboarding and Support</h6></dt>
							<dd class="fs-20 color-white fw-100 lh-14 animate__fadeIn delay-5"
							    v-in-viewport="{class:['animate__animated']}">We work with you to develop interview
							                                                  questions
							                                                  and
							                                                  evaluation criteria, and we conduct
							                                                  thorough
							                                                  interviews to assess candidates' fit for
							                                                  the
							                                                  position and
							                                                  your company culture. We also assist
							                                                  with
							                                                  final candidate selection and offer
							                                                  negotiations.
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</section>
		<section id="testimonials" class="testimonial back-white ">
			<div class="container">
				<h3 class="font-center fs-40 lh-12 fw-800" v-in-viewport="{class:['animatedTitle']}"><span>Hear It Straight From Our Clients</span>
				</h3>
				<div class="comments-container">
					<comments-slider></comments-slider>
				</div>
			</div>
		</section>
		<section class="collaborate color-white back-gray_dark pt-12">
			<div class="container">
				<h3 class="font-center fs-40 lh-12 fw-800 color-white mb-8"
				    v-in-viewport="{class:['animatedTitle']}"><span>Collaborating with the best</span>
				</h3>
				<div class="customers">
					<ul role="list">
						<li><img :src="$store.getters.getPaths.media +'media/companies/borgplaats-logotype.svg'" alt="">
						</li>
						<li><img :src="$store.getters.getPaths.media +'media/companies/somi-logo.svg'" alt=""></li>
						<li><img
								:src="$store.getters.getPaths.media +'media/companies/hestiva-gratis-woningplatform.svg'"
								alt=""></li>
						<li><img :src="$store.getters.getPaths.media +'media/companies/vondellaan.svg'" alt=""></li>
						<li><img :src="$store.getters.getPaths.media +'media/companies/tanaruz-logo.svg'" alt=""></li>
					</ul>
				</div>
			</div>
			<div class="dont-stay">
				<h6 class="uppercase">DON’T Stay</h6>
				<badge-scroller></badge-scroller>
				<h6 class="uppercase">a stranger</h6>
			</div>
		</section>
	</main>
	<foooter></foooter>
</template>
<script>

export default {
	name: 'App',
	data: () => {
		return {}
	},
	methods: {},
	created() {
		this.$store.dispatch('setUp');
	}
}
</script>