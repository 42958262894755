<template>
	<div class="badge-scroller" ref="badgeCont" :class="[{'vis': move}]" :style="innerStyles">
		<div class="badge-set" v-for="item in 20">
			<i class="line"></i>
			<span>We'd<img class="heart" :src="$store.getters.getPaths.media +'img/icons/heart.svg'">to meet you</span>
			<i class="line"></i>
			<b class="uppercase">Let's get <br> in touch</b>
		</div>
	</div>
</template>
<script>
export default {
	name: 'BadgeScroller',
	computed: {
		innerStyles() {
			return {
				transform: "rotate(-8deg) translateX(calc(-" + Math.abs(this.count) + " * var(--size-5))"
			}
		}
	},
	data() {
		return {
			move: false,
			count: 0,
			scrollPos: 0,
			watchElm: null
		}
	},
	methods: {
		setUp() {
			this.watchElm = this.$refs.badgeCont.parentElement;
			this.setObserver();
			document.addEventListener('scroll', this.scrollChange, {passive: true});
		},
		scrollChange() {
			if ((document.body.getBoundingClientRect()).top > this.scrollPos) {
				this.count--;
			} else {
				this.count++;
			}
			this.scrollPos = (document.body.getBoundingClientRect()).top;
		},
		setObserver() {
			let _this = this;

			function doIt() {
				_this.move = true;
			}

			function undoIt() {
				_this.move = false;
			}

			function handleIntersect(entries, observer) {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						// observer.unobserve(_this.$refs.badgeCont);
						doIt();
					} else {
						undoIt()
					}
				});
			}

			function createObserver() {
				const options = {
					root: null,
					threshold: 0
				};
				const observer = new IntersectionObserver(handleIntersect, options);
				observer.observe(_this.watchElm);
			}

			if (window["IntersectionObserver"]) {
				createObserver();
			}
		}
	},
	mounted() {
		this.setUp();
	}
}
</script>